import { first, last, uniq } from "lodash";
import { getCachedRows } from "source/hooks/document-list/grid/useHandleGroupExpandCaching";
import {
  CreateFolderRequest,
  FolderItemType,
  ResyncExternalFolderRequest,
} from "source/types/document-list/documentList.types";
import {
  DocumentListGridApi,
  DocumentListGridDataType,
  DocumentListIRowNode,
} from "source/types/document-list/grid.types";
import { filterNullishValues, filterUndefined } from "../common/arrays";

export const getRootFolderName = (file_path: string | null | undefined) => {
  if (!file_path) return null;
  const trimmedPath = file_path.replace(/^\/+/, "");
  const segments = trimmedPath.split("/");

  return segments.length > 1 ? segments[0] : null;
};
/**
 * Trims the parent folder from a given file path.
 * @param path - The file path string.
 * @returns The file path string without the parent folder.
 */
export const trimParentFolder = (file_path: string) => {
  const parts = file_path?.split("/");
  return parts.slice(2).join("/");
};

export const getCachedExpandedRoutes = (
  documentListId: string,
  gridApi?: DocumentListGridApi
) => {
  return Object.entries(getCachedRows(documentListId) ?? {})?.reduce<
    string[][]
  >((acc, [k, v]) => {
    const filePath = gridApi?.getRowNode(k)?.data?.filePath;
    if (v && filePath) acc.push(filePath);
    return acc;
  }, []);
};

/**
 * if parentNode is a document, add the folder as the same level as the document
 * if parentNode is a folder, just use it's own id
 * if parentNode is null, default to rootFolderId
 * @param parentNode
 * @param name
 * @param rootFolderId
 * @returns
 */
export const getCreateFolderParams = (
  parentNode?: DocumentListIRowNode | null,
  name?: string,
  rootFolderId?: string | null,
  selectedRows?: DocumentListGridDataType[]
): CreateFolderRequest => {
  let parentId;

  if (parentNode?.data?.type === FolderItemType.DOCUMENT) {
    parentId = last(parentNode?.data?.filePath);
  } else if (parentNode?.data?.type === FolderItemType.FOLDER) {
    parentId = parentNode?.data?.id;
  } else if (!parentNode) {
    parentId = rootFolderId;
  }

  return {
    parent_id: parentId,
    name: name ?? "Untitled Folder",
    items: selectedRows,
  };
};

export const getCreateFolderRefreshRoute = (
  parentNode?: DocumentListIRowNode | null
): string[] => {
  const route = parentNode?.data?.filePath ?? [];
  if (parentNode?.data?.type === FolderItemType.FOLDER)
    route.push(parentNode?.data?.id);
  return filterNullishValues(uniq(route));
};

export const getResyncIntegrationFolderParams = (
  parentNode: DocumentListIRowNode
): ResyncExternalFolderRequest => {
  const crawlId = parentNode?.data?.crawl_id ?? "";
  return {
    crawl_ids: [crawlId],
  };
};
