import { FileRejection, FileWithPath } from "react-dropzone";
import { UploadFolderRequest } from "source/types/document-list/documentList.types";
import { chunkFilesAndDocIdsByFileSize } from "../matrix/addDocs";
import { FASTBUILD_CHUNK_SIZE_LIMIT } from "source/constants/fastBuild";
import { first } from "lodash";
import {
  removeLeadingSlashFromFilename,
  shouldCreateFolder,
} from "../common/files";
import { getRootFolderName } from "./folders";
import api from "source/api";
import { upsertToast } from "source/redux/ui";
import { pluralize } from "../common/strings";
import pLimit from "p-limit"; // Install this library if not already present
import logger from "source/utils/logger";

const CONCURRENCY_LIMIT = 15; // Adjust based on API/server capacity
const MAX_RETRIES = 5;

export const validateFilesOnDrop = (
  acceptedFiles: File[],
  rejectedFiles: FileRejection[],
  dispatch: any
) => {
  // Dear DocLists team, I went ahead and added a basic error case handling here but feel free to do whatever you'd like instead
  if (!acceptedFiles.length) {
    if (rejectedFiles.length) {
      dispatch(
        upsertToast({
          id: "fastBuildUploadFailure",
          primaryText: `Unable to upload your ${pluralize(rejectedFiles.length, "file")}.`,
          secondaryText: "Make sure your files are in a supported format.",
          icon: "error",
        })
      );
    }
    return [];
  }

  return acceptedFiles;
};

/**
 * @summary UI and State agnostic function to upload a set of documents to a document list
 * @returns FastBuild document IDs
 */
export const uploadFiles = async (
  files: FileWithPath[],
  documentListId: string,
  isQuickUpload?: boolean,
  uploadFolderParams?: Partial<UploadFolderRequest>,
  onFolderCreated?: (rootPath?: string[]) => void,
  onChunkUploaded?: (
    fileChunk: File[],
    rootPath?: string[],
    paths?: string[][]
  ) => void
) => {
  const firstFile = first(files);
  const firstFilePath =
    firstFile?.path || firstFile?.webkitRelativePath || firstFile?.name;
  const createFolder = shouldCreateFolder(firstFilePath);

  let newFolderId: string | undefined;

  if (createFolder) {
    // Determine folder name and create folder if needed
    const folderName =
      getRootFolderName(removeLeadingSlashFromFilename(firstFilePath)) ||
      "Untitled Folder";

    const newFolder = await api.documentList.createFolder(documentListId, {
      name: folderName,
      parent_id: uploadFolderParams?.rootFolderId,
      is_quick_upload: isQuickUpload,
    });
    newFolderId = newFolder.id;

    onFolderCreated && onFolderCreated(uploadFolderParams?.rootFolderPath);
  }

  // Chunk files based on size limit
  const { fileChunks } = chunkFilesAndDocIdsByFileSize({
    files: files,
    maxSize: FASTBUILD_CHUNK_SIZE_LIMIT,
  });

  // Initialize concurrency limit
  const limit = pLimit(CONCURRENCY_LIMIT);

  // Upload files with concurrency control + retries
  const uploadPromises = fileChunks.map((fileChunk) =>
    limit(async () => {
      let attempt = 0;

      while (attempt < MAX_RETRIES) {
        try {
          const { document_ids: newDocumentIds, paths } =
            await api.documentList.uploadFolder({
              documentListId,
              files: fileChunk,
              ...uploadFolderParams,
              rootFolderId: newFolderId ?? uploadFolderParams?.rootFolderId,
            });
          // Resolve the path of the chunk to refresh
          let rootPath = uploadFolderParams?.rootFolderPath ?? [];
          if (newFolderId) {
            rootPath = [...rootPath, newFolderId];
          }

          onChunkUploaded && onChunkUploaded(fileChunk, rootPath, paths);

          return newDocumentIds; // Return new document IDs for this chunk
        } catch (error: any) {
          attempt++;
          logger.error(
            `Error uploading files (attempt ${attempt}/${MAX_RETRIES})`,
            error
          );

          if (attempt >= MAX_RETRIES) {
            logger.error("Max retries reached. Throwing error.");
            // After 5 attempts, re-throw the error
            return Promise.reject(fileChunk.map((file) => file.name));
          } else {
            // Optionally, wait before retrying
            await new Promise((res) => setTimeout(res, 1000 * attempt));
          }
        }
      }
    })
  );

  // Wait for all uploads to complete while respecting the concurrency limit
  return await Promise.allSettled(uploadPromises);
};

/**
 * @summary UI and State agnostic function to upload a list of URLs to a document list
 * @returns FastBuild document IDs
 */
export const uploadURLs = async (urls: string[], documentListId: string) => {
  return await api.documentList.uploadURLs(documentListId, {
    urls: urls.map((url) => ({
      url,
    })),
  });
};
